import AuthComponentBase from '@/shared/application/auth-component-base';
import Guid from '@/shared/helpers/guid-helper';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { NameValue } from '../../shared/models/dto/general';
import { Permission } from '../../shared/models/dto/permissions';
import { CreateOrUpdateRoleInput, GetRoleForCreateOrUpdateOutput, Role } from '../../shared/models/dto/roles';

@Component({
    components: {
        errorMessage: require('@/views/_components/error-message/error-message.vue').default
    }
})
export default class EditCreateRoleDialogComponent extends AuthComponentBase {
    @Prop({ default: null }) readonly id: string;
    @Prop({ default: false }) readonly show: boolean;

    refs = this.$refs as any;
    loading = true;
    saving = false;
    valid = true;
    isEdit = false;
    formTitle = '';
    allPermissions: Permission[] = [];
    permissionList: any[] = [];

    errors: NameValue[] = [];

    createOrUpdateInput = {
        grantedPermissionIds: [],
        role: {} as Role
    } as CreateOrUpdateRoleInput;


    @Watch('show', { immediate: true })
    onShowChanged() {
        if (this.show) {
            this.loading = true;
            this.isEdit = !!this.id;
            this.formTitle = `${this.isEdit ? this.t('Edit') : this.t('New')} ${this.t('Role').toLowerCase()}`;
            this.errors = [];

            this.authService.get<GetRoleForCreateOrUpdateOutput>(
                '/api/roles/' + (this.isEdit ? this.id : Guid.empty)
            ).then((response) => {
                const result: GetRoleForCreateOrUpdateOutput = response.content;

                this.allPermissions = result.allPermissions;
                this.setPermissionList();

                this.createOrUpdateInput = {
                    grantedPermissionIds: result.grantedPermissionIds,
                    role: result.role
                };

                this.loading = false;
            });
        }
    }

    setPermissionList(): any {
        this.permissionList = [];
        /* eslint-disable  @typescript-eslint/no-this-alias */
        const that = this;

        Object.keys(this.allPermissions).forEach(j => {
            let g = null;
            that.permissionList.forEach((o, i) => {
                if (o.permissionGroupDisplayName === that.allPermissions[j].permissionGroup.displayName) {
                    g = i;
                }
            });

            if (g === null) {
                that.permissionList.push({
                    permissionGroupDisplayName: that.allPermissions[j].permissionGroup.displayName,
                    items: []
                });

                that.permissionList[that.permissionList.length - 1].items.push(that.allPermissions[j]);
            } else {
                that.permissionList[g].items.push(that.allPermissions[j]);
            }
        });
        /* eslint-enable  @typescript-eslint/no-this-alias */

        return this.permissionList;
    }

    save() {
        if (this.refs.form.validate()) {
            this.saving = true;
            this.errors = [];
            this.authService.postOrPut<void>(
                '/api/roles',
                this.createOrUpdateInput as CreateOrUpdateRoleInput,
                this.createOrUpdateInput.role.id
            ).then((response) => {
                if (!response.isError) {
                    this.swalToast(2000, 'success', this.t('ChangesSaved'));
                    this.$emit('completed', true);
                } else {
                    response.errors.forEach(e => this.errors.push(e));
                }
            });

            this.saving = false;
        }
    }

    close() {
        this.$emit('completed', false);
    }
}